<template>
  <div class="container">
    <div class="commerces__wrapper screen__wrapper">
      <CardCommerce
        v-for="commerce in commerces"
        :key="commerce.id"
        :obj="commerce"
        @click.native="info(commerce)"
      />
    </div>
  <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import CardCommerce from '../Card'
import InfiniteLoading from 'vue-infinite-loading'
import Info from '../Info'

export default {
  name: "ArchiveRentCommerces",
  components: {
    CardCommerce,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    commerces() {
      return this.$store.getters['archive/rent/commerce/commerce']
    },
    hasMoreResults () { 
      return this.commerces.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['archive/rent/commerce/pagination']?.total
    },
    page() {
      return this.$store.getters['archive/rent/commerce/page']
    }
  },
  methods: {
    async loadObj() {
      this.isLoading = true
      const page = this.page
      try {
        await this.$store.dispatch('archive/rent/commerce/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    info(info) {
      this.$modal.display(Info, {info}, {name: 'InfoRent'})
    }
  },
};
</script>